import type { Action, IBotInfo, IMessage, Reply, Suggestions, RCSMessage } from "../../typings";
import { maxMessages } from "../../chat/chat";

type ChatHistoryOriginal = {
  timestamp: number;
  direction: "incoming" | "outgoing";
  payload: RCSMessage;
}[];

export async function getMessageHistory(
  botId: string,
  userId: string
): Promise<IMessage[] | undefined> {
  if (!botId || !userId) {
    return;
  }

  const chatHistoryUrl = new URL(
    `/api/chatbot/${botId}/user/${userId}/history`,
    import.meta.env.VITE_BUILDER_URL
  );

  console.debug("Getting message history from:", chatHistoryUrl.toString());
  const messages = (await (await fetch(chatHistoryUrl)).json()) as ChatHistoryOriginal;

  if (!Array.isArray(messages)) {
    return;
  }

  const newMessages: IMessage[] = [];
  let i = messages.length - maxMessages;
  if (i < 0) {
    i = 0;
  }
  for (; i < messages.length; i++) {
    const msg = messages[i];

    const userMsg = msg.direction !== "outgoing";

    let textMessage: string | undefined = typeof msg.payload === "string" ? msg.payload : undefined;

    if (userMsg) {
      // skip/remove duplicate user messages (e.g. restart messages)
      if (messages[i - 1]?.payload === msg.payload) {
        continue;
      }

      // replace "nextState=" with the original suggestion text
      // if the message text starts with "nextState="
      if (textMessage?.startsWith("nextState=")) {
        // find previous richcard message with a suggestion that matches the nextState of the current message
        prevMsgLoop: for (let j = i; j >= 0; j--) {
          const prevMsg = messages[j];

          const botMsg = prevMsg.direction === "outgoing";
          if (!botMsg) {
            continue;
          }

          // payload.RCSMessage is almost an IMessage. It is missing a few fields like "from", but cast it anyways
          const msg = prevMsg.payload.RCSMessage as IMessage;
          // prevMsg.payload could be a string; continue if it is.
          if (!msg) {
            continue;
          }

          // if the message has suggestions (not card suggestions)
          if (Array.isArray(msg.suggestedChipList?.suggestions)) {
            // loop through message suggestions
            for (const s of msg.suggestedChipList.suggestions) {
              if ((s as Action).action) {
                continue;
              }
              if ((s as Reply).reply.postback.data === textMessage) {
                // found original suggestion text
                textMessage = (s as Reply).reply.displayText;
                break prevMsgLoop;
              }
            }
          }

          if (!msg.richcardMessage?.message) {
            continue;
          }

          let richcardSuggestions: Suggestions =
            msg.richcardMessage.message.generalPurposeCard?.content?.suggestions;

          if (
            !richcardSuggestions &&
            Array.isArray(msg.richcardMessage.message.generalPurposeCardCarousel?.content)
          ) {
            richcardSuggestions = [];
            for (const c of msg.richcardMessage.message.generalPurposeCardCarousel.content) {
              if (Array.isArray(c?.suggestions)) {
                richcardSuggestions.push(...c.suggestions);
              }
            }
          }
          if (richcardSuggestions) {
            // loop through richcard suggestions
            for (const s of richcardSuggestions) {
              if ((s as Action)?.action) {
                continue;
              }
              if ((s as Reply)?.reply?.postback?.data === textMessage) {
                // found original suggestion text
                textMessage = (s as Reply).reply.displayText;
                break prevMsgLoop;
              }
            }
          }
        }
      }
    }

    // convert messages to web widget IMessage format
    newMessages.push({
      from: userMsg ? "user" : "chatbot",
      textMessage,
      ...(typeof msg.payload?.RCSMessage === "object" && msg.payload.RCSMessage),
    } as IMessage);
  }
  return newMessages;
}

export async function getBotInfo(botId: string): Promise<IBotInfo> {
  const res = await fetch(`${import.meta.env.VITE_BUILDER_URL}/chatbot/${botId}/info`);
  const botInfo = await res.json();

  const botInfoRes = {
    persistentMenu: botInfo["persistent-menu"],
    richCardMediaSize: botInfo.richCardMediaSize,
    richCardMediaPosition: botInfo.richCardMediaPosition,
  };
  return Object.fromEntries(Object.entries(botInfoRes).filter(([_, v]) => !!v));
}
