import type { CSSProperties } from "preact/compat";

export const desktopWrapperStyle: CSSProperties = {
  position: "fixed",
  bottom: "20px",
  right: "20px",
  zIndex: 2147483647,
  borderRadius: "8px",
  boxSizing: "content-box",
};

export const desktopClosedWrapperStyleChat: CSSProperties = {
  position: "fixed",
  bottom: "0px",
  right: "0px",
  zIndex: 2147483647,
  minWidth: "100px",
  boxSizing: "content-box",
  overflow: "hidden",
  minHeight: "120px",
  pointerEvents: "none",
};

export const mobileClosedWrapperStyle: CSSProperties = {
  position: "fixed",
  bottom: "0px",
  right: "0px",
  zIndex: 2147483647,
  minWidth: "100px",
  boxSizing: "content-box",
  overflow: "hidden",
  minHeight: "120px",
};

export const mobileOpenWrapperStyle: CSSProperties = {
  position: "fixed",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 2147483647,
  width: "100%",
  height: "100%",
  overflowY: "visible",
  boxSizing: "content-box",
};

export const desktopTitleStyle: CSSProperties = {
  height: "40px",
  lineHeight: "30px",
  fontSize: "20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "5px 0 5px 1rem",
  fontFamily: "Lato, sans-serif",
  color: "#fff",
  cursor: "pointer",
  boxSizing: "content-box",
  mozBoxSizing: "content-box",
  webkitBoxSizing: "content-box",
  borderRadius: "8px 8px 0 0",
};

export const desktopClosedMessageStyle: CSSProperties = {
  letterSpacing: "1px",
  color: "#fff",
  display: "block",
  position: "absolute",
  fontSize: "14px",
  top: 0,
  right: "130px",
  marginTop: "23px",
  borderRadius: "5px",
  padding: "15px 20px",
  boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)",
  // pointerEvents: "auto"
};

export const desktopIntroMessageStyle: CSSProperties = {
  width: 0,
  height: 0,
  position: "absolute",
  top: "12px",
  right: "-10px",
  borderTop: "10px solid transparent",
  borderBottom: "10px solid transparent",
};

export const desktopClosedMessageAvatarStyle: CSSProperties = {
  display: "flex",
  justifyContent: "center",
  position: "absolute",
  top: "38px",
  right: "20px",
  height: "60px",
  width: "60px",
  padding: "4px",
  border: 0,
  borderRadius: "50%",
  boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)",
  pointerEvents: "auto",
};

export const mobileClosedMessageAvatarStyle: CSSProperties = {
  display: "block",
  position: "absolute",
  top: "46px",
  right: "20px",
  height: "52px",
  width: "52px",
  padding: "2px",
  border: 0,
  borderRadius: "50%",
  boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)",
  pointerEvents: "auto",
};

export const closedChatAvatarImageStyle: CSSProperties = {
  width: "100%",
  height: "auto",
  objectFit: "cover",
  borderRadius: "999px",
};
