import { IConfiguration } from "../typings";
import { desktopTitleStyle } from "./style";
import ArrowIcon from "./arrow-icon";
import RestartIcon from "./restart-icon";
import Widget from "./widget";

function WidgetTextColorStyle({
  widgetClassName,
  conf,
  brightnessThreshold = 72,
}: {
  widgetClassName: string;
  conf: IConfiguration;
  brightnessThreshold?: number;
}) {
  const hexToRgb = (hex: string) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : {
          r: 255,
          g: 255,
          b: 255,
        };
  };

  // set color
  const { r, g, b } = hexToRgb(conf.mainColor);
  // https://www.w3.org/TR/AERT#color-contrast
  const brightness = Math.round((r * 299 + g * 587 + b * 114) / 1000);
  const textColor = brightness > brightnessThreshold ? 0 : 255;
  conf.textColor = `rgb(${Array(3).fill(textColor).join(",")})`;

  const textColorRgb = `rgb(${textColor},${textColor},${textColor})`;
  const textColorInvertedRgb = `rgb(${Array(3)
    .fill(255 - textColor)
    .join(",")})`;

  return (
    <style
      dangerouslySetInnerHTML={{
        __html: `.${widgetClassName} {
            box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5), 0 0 20px rgba(0, 0, 0, 0.2);
          }
          .${widgetClassName} > * { 
            filter: 
                drop-shadow(1px 1px 0px rgba(${textColorInvertedRgb}, 0.2)) 
                drop-shadow(0.5px 1px 1px rgba(${textColorInvertedRgb}, 0.6));
          }
          .${widgetClassName} * { 
            color: ${textColorRgb} !important;
            fill: ${textColorRgb} !important;
            stroke: ${textColorRgb} !important;
          }`,
      }}
    />
  );
}

type Props = {
  conf: IConfiguration;
  widget: Widget;
};

export default function Header({ conf, widget }: Props) {
  const widgetClassName = "widget-header";

  return (
    <div
      class={widgetClassName}
      style={{ background: conf.mainColor, ...desktopTitleStyle }}
      onClick={widget.toggle}
    >
      <WidgetTextColorStyle conf={conf} widgetClassName={widgetClassName} />
      <div style={{ display: "flex" }}>
        {conf.bubbleAvatarUrl.indexOf("/") !== -1 && (
          <img
            src={conf.bubbleAvatarUrl}
            style={{
              width: "30px",
              height: "30px",
              objectFit: "cover",
              userSelect: "none",
            }}
          />
        )}
        <div
          class="widget-title"
          style={{
            userSelect: "none",
            padding: "0 0.5rem 0 1rem",
            fontSize: "15px",
            fontWeight: "normal",
            color: conf.headerTextColor,
            // makes title text ellipsis when it's too long
            display: "-webkit-box",
            "-webkit-line-clamp": "1",
            "-webkit-box-orient": "vertical",
            wordBreak: "break-all",
          }}
        >
          {conf.title}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          marginRight: "1rem",
        }}
      >
        <RestartIcon onClick={widget.reload} />
        <ArrowIcon isOpened />
      </div>
    </div>
  );
}
