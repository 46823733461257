import Widget from "./widget";
import { IMessage } from "../typings";

export default class Api {
  widget: Widget;

  constructor(widget: Widget) {
    this.widget = widget;
  }

  checkServerStatus(chatServer: RequestInfo) {
    return this.widget.checkServerStatus(chatServer);
  }

  reload() {
    this.widget.reload();
  }

  reloadAsync() {
    return this.widget.reloadAsync();
  }

  open() {
    this.widget.open();
  }

  close() {
    this.widget.close();
  }

  toggle() {
    this.widget.toggle();
  }

  isOpen() {
    return this.widget.state.isChatOpen === true;
  }

  callChatWidget(payload: Object) {
    const frame = document.getElementById("chatBotManFrame") as HTMLIFrameElement;
    if (this.isOpen()) {
      frame.contentWindow.postMessage(payload, "*");
    } else {
      try {
        this.open();
        setTimeout(() => {
          frame.contentWindow.postMessage(payload, "*");
        }, 750);
      } catch (e) {
        console.error(e);
      }
    }
  }

  writeToMessages(message: IMessage) {
    this.callChatWidget({
      method: "writeToMessages",
      params: [message],
    });
  }

  sayAsBot(msg: any) {
    this.callChatWidget({
      method: "sayAsBot",
      params: [msg],
    });
  }

  say(msg: any, data = "", interactiveReply = false, showMessage = true) {
    this.callChatWidget({
      method: "say",
      params: [msg, data, interactiveReply, showMessage],
    });
  }

  whisper(msg: any, data = "", interactiveReply = false) {
    this.callChatWidget({
      method: "whisper",
      params: [msg, data, interactiveReply],
    });
  }
}
