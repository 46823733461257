import { Component, createRef } from "preact";
import type { IBotInfo, IConfiguration, IMessage } from "../typings";

export default class ChatFrame extends Component<IChatFrameProps> {
  private iframeRef = createRef<HTMLIFrameElement>();

  sendNewConf = (newConf: IConfiguration) => {
    this.iframeRef.current.contentWindow.postMessage({ method: "setConf", params: [newConf] }, "*");
  };

  shouldComponentUpdate(nextProps: IChatFrameProps) {
    if (nextProps.conf !== this.props.conf && this.iframeRef) {
      // todo check if the conf has changes that can be used by the chat frame. Simple Title change doesn't need to be sent.
      this.sendNewConf(nextProps.conf);

      return false;
    }
    return true;
  }

  render({ iFrameSrc, isMobile, conf, messageHistory, botInfo: botInfoPromise }: IChatFrameProps) {
    const encodedConf = encodeURIComponent(JSON.stringify(conf));
    iFrameSrc += "?conf=" + encodedConf;

    if (import.meta.env.DEV) {
      console.debug("Loading iFrame", iFrameSrc);
    }

    const iFrameLoadHandler = (e: Event) => {
      const frame = e.currentTarget as HTMLIFrameElement;

      if (messageHistory) {
        (async () => {
          const messages = await messageHistory;
          if (!messages) {
            return;
          }
          frame.contentWindow.postMessage(
            {
              method: "setMessageHistory",
              params: [messages],
            },
            "*"
          );
        })();
      }

      if (botInfoPromise) {
        (async () => {
          const botInfo = await botInfoPromise;
          if (botInfo && Object.keys(botInfo).length > 0) {
            frame.contentWindow.postMessage(
              {
                method: "setBotInfo",
                params: [botInfo],
              },
              "*"
            );
          }
        })();
      }
    };

    return (
      <iframe
        ref={this.iframeRef}
        id="chatBotManFrame"
        src={iFrameSrc}
        width="100%"
        height={isMobile ? "94%" : "100%"}
        frameBorder="0"
        allowTransparency
        style={{
          background: conf.darkMode ? "#1f1f1f" : "white",
        }}
        onLoad={iFrameLoadHandler}
      />
    );
  }
}

interface IChatFrameProps {
  iFrameSrc: string;
  conf: IConfiguration;
  isMobile: boolean;
  messageHistory?: Promiseable<IMessage[] | undefined>;
  botInfo?: Promiseable<IBotInfo | undefined>;
}
