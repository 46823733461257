import gsap from "gsap";
import { MutableRef, useLayoutEffect } from "preact/hooks";
import { desktopClosedMessageAvatarStyle } from "../style";

const defaultExpandTime = 0.25;

function initStyles(fullWrapper: HTMLDivElement) {
  Object.assign(fullWrapper.style, {
    willChange: "max-width, max-height, border-radius",
    width: "100%",
    height: "100%",
    overflow: "hidden",
  } as CSSStyleDeclaration);
}

function removeStyles(fullWrapper: HTMLDivElement) {
  [
    "will-change",
    "overflow",
    "width",
    "height",
    "max-width",
    "max-height",
    "border-radius",
  ].forEach((property) => fullWrapper.style.removeProperty(property));
}

function makeAnimation(
  wrapper: HTMLDivElement,
  fullWrapper: HTMLDivElement,
  reverse = false,
  duration = defaultExpandTime
) {
  initStyles(fullWrapper);

  const { width, height } = wrapper.getBoundingClientRect();

  const from: gsap.TweenVars = {
    duration: duration,
    maxWidth: desktopClosedMessageAvatarStyle.width,
    maxHeight: desktopClosedMessageAvatarStyle.height,
    borderRadius: `${height}px 0 0 0`, // can be width or height value, doesn't matter to css
  };
  const to: gsap.TweenVars = {
    duration: duration * 1.5,
    maxWidth: `${width}px`,
    maxHeight: `${height}px`,
    borderRadius: "0",
  };

  let order: [gsap.TweenVars, gsap.TweenVars];
  if (!reverse) {
    order = [from, to];
  } else {
    order = [to, from];
  }
  return gsap.fromTo(fullWrapper, ...order).eventCallback("onComplete", () => {
    removeStyles(fullWrapper);
  });
}

export function expandWidget(
  wrapper: HTMLDivElement,
  fullWrapper: HTMLDivElement,
  expandTime = defaultExpandTime
) {
  return new Promise<gsap.core.Tween>((res) => {
    if (!wrapper || !fullWrapper) {
      return res(null);
    }

    const tween = makeAnimation(wrapper, fullWrapper, false, expandTime).eventCallback(
      "onComplete",
      () => {
        res(tween);
      }
    );
  });
}

export function shrinkWidget(
  wrapper: HTMLDivElement,
  fullWrapper: HTMLDivElement,
  shrinkTime = defaultExpandTime
) {
  return new Promise<gsap.core.Tween>((res) => {
    if (!wrapper || !fullWrapper) {
      return res(null);
    }

    const tween = makeAnimation(wrapper, fullWrapper, true, shrinkTime).eventCallback(
      "onComplete",
      () => {
        res(tween);
      }
    );
  });
}

export function useExpandWidget(
  expanded: boolean,
  wrapperRef: MutableRef<HTMLDivElement>,
  fullWrapperRef: MutableRef<HTMLDivElement>,
  expandTime = defaultExpandTime
) {
  useLayoutEffect(() => {
    if (!expanded) return;

    makeAnimation(wrapperRef.current, fullWrapperRef.current, false, expandTime);
  }, [expanded, wrapperRef.current, fullWrapperRef.current]);
}
