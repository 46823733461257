import { IConfiguration } from "../typings";

const prefersReducedMotion = window.matchMedia("(prefers-reduced-motion: reduce)").matches;

export const defaultConfiguration: IConfiguration = {
  frameEndpoint: "/chat.html",
  timeFormat: "HH:MM",
  dateTimeFormat: "m/d/yy HH:MM",
  title: "Chatbot",
  introMessage: "",
  placeholderText: "Send a message...",
  displayMessageTime: true,
  mainColor: "#5799c3",
  textColor: "white",
  darkMode: false,
  headerTextColor: "#fff",
  bubbleBackground: "",
  bubbleAvatarUrl: "",
  desktopHeight: 520,
  desktopWidth: 420,
  mobileHeight: "100%",
  mobileWidth: "100%",
  videoHeight: 160,
  aboutLink: "https://www.summit-tech.ca",
  aboutText: "By Summit∿Tech",
  botId: "",
  userId: "",
  saveMessages: false,
  alwaysUseFloatingButton: false,
  enableUserResizing: true,
  richCardMediaSize: "contain",
  richCardMediaPosition: "center",
  animateWidgetOpen: !prefersReducedMotion, // other default: true
  reducedMotion: prefersReducedMotion, // other default: false
};
