import { render } from "preact";
import { useEffect, useRef, useState } from "preact/hooks";
import { v4 as uuid } from "uuid";
import Widget from "./widget";
import { defaultConfiguration } from "./configuration";
import { IConfiguration } from "../typings";

if (document.readyState === "complete" || document.readyState === "interactive") {
  // if document has already been loaded, inject the chat
  injectChat();
} else {
  document.addEventListener("DOMContentLoaded", injectChat, { once: true });
}

function assignConfAliases(conf: IConfiguration) {
  // if no bubbleBackground is set, the default mainColor is used
  if (conf.mainColor) conf.bubbleBackground ??= conf.mainColor;
  if (conf.height) {
    conf.desktopHeight ??= conf.height;
    conf.mobileHeight ??= conf.height;
  }
  if (conf.width) {
    conf.desktopWidth ??= conf.width;
    conf.mobileWidth ??= conf.width;
  }

  return conf;
}

function getConfInit() {
  // ! deprecated
  window.webWidgetConfig ??= window.botmanWidget ?? ({} as IConfiguration);

  window.webWidgetConfig.userId ??= uuid();

  assignConfAliases(window.webWidgetConfig);

  return { ...defaultConfiguration, ...window.webWidgetConfig };
}

function instanceofProxy(object: unknown) {
  try {
    return object instanceof Proxy;
  } catch {
    return false;
  }
}

function createConfigProxy(onUpdate: (conf: IConfiguration) => void) {
  if (instanceofProxy(window.webWidgetConfig)) {
    return;
  }

  // ! deprecated
  if (instanceofProxy(window.botmanWidget)) {
    return;
  }
  if (window.botmanWidget) {
    window.botmanWidget = new Proxy(window.botmanWidget, {
      set(_t, p, newValue, receiver) {
        return Reflect.set(window.webWidgetConfig, p, newValue, receiver);
      },
    });
  }

  window.webWidgetConfig = new Proxy(window.webWidgetConfig, {
    set(target, p, newValue, receiver) {
      try {
        return Reflect.set(target, p, newValue, receiver);
      } finally {
        // using try/finally to execute code after returning.
        onUpdate(window.webWidgetConfig);
      }
    },
  });
}

function WidgetWithDynamicConf() {
  const confSendTimeout = useRef<any>();
  const [conf, _setConf] = useState<IConfiguration>(getConfInit());

  const setConf = (newConf: IConfiguration) => {
    // this will batch set the new conf if many properties are changed at once
    // lowers number of re-renders and number of sends to the embedded iframe
    clearTimeout(confSendTimeout.current);
    confSendTimeout.current = setTimeout(() => {
      _setConf({ ...conf, ...assignConfAliases(newConf) });
    }, 10);
  };

  useEffect(() => {
    createConfigProxy(setConf);
  });

  return <Widget isMobile={window.screen.width < 500} iFrameSrc={conf.frameEndpoint} conf={conf} />;
}

function injectChat() {
  const root = document.createElement("div");
  root.id = "botmanWidgetRoot";
  root.style.position = "relative";
  document.body.appendChild(root);

  render(<WidgetWithDynamicConf />, root);
}

declare global {
  interface Window {
    attachEvent: Function;
    webWidgetConfig: IConfiguration;
    // ! deprecated
    botmanWidget: IConfiguration;
  }
}
